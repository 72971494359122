var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('my-page-header',{attrs:{"title":_vm.$t('page.title',{ name: _vm.company.name }),"description":_vm.$t('page.description',{ name: _vm.company.name })}}),_vm._v(" "),_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('input',{staticClass:"hidden",attrs:{"name":"fakeusernameremembered"}}),_vm._v(" "),_c('input',{staticClass:"hidden",attrs:{"type":"password","name":"fakepasswordremembered"}}),_vm._v(" "),_c('a-form-item',{staticStyle:{"text-align":"center","margin-bottom":"16px"},attrs:{"help":_vm.$t('field.image.help'),"label-col":{ span: 0 },"wrapper-col":{ span: 24 }}},[_c('ImageUploader',{attrs:{"border-type":"circle","size":"large","max-width":512,"max-height":512},on:{"error":_vm.handleFileUploadError,"change":_vm.hadleFileUploadChange}},[_c('a-icon',{attrs:{"type":"user"}})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.user_role')}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'user_role' , { initialValue: 'user' } ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.user_role') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'user_role' , { initialValue: 'user' } ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.user_role') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}]},[_c('a-radio',{attrs:{"value":"admin"}},[_vm._v(_vm._s(_vm.$tenum('user_role','1')))]),_vm._v(" "),_c('a-radio',{attrs:{"value":"user"}},[_vm._v(_vm._s(_vm.$tenum('user_role','2')))]),_vm._v(" "),_c('a-radio',{attrs:{"value":"technician"}},[_vm._v(_vm._s(_vm.$tenum('user_role','3')))])],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.username')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'username' ,
							{ rules: [
								{ required: true, message: _vm.$tt('validate.required','field.username') },
								{ min: 3 ,message: _vm.$tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
								{ max: 100 ,message: _vm.$tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'username' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.username') },\n\t\t\t\t\t\t\t\t{ min: 3 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,\n\t\t\t\t\t\t\t\t{ max: 100 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","autocomplete":"off","placeholder":_vm.$t('field.username.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.password'),"extra":_vm.$t('common.password.pattern')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'password' ,
							{ rules: [
								{ required: true, message: _vm.$tt('validate.required','field.password') },
								{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('common.password.pattern') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'password' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.password') },\n\t\t\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('common.password.pattern') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"new-password","type":"password","placeholder":_vm.$t('field.password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.full_name')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'full_name' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.full_name') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'full_name' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.full_name') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('field.full_name.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone']),expression:"['phone']"}],attrs:{"type":"tel","max-length":20,"placeholder":_vm.$t('field.phone.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'email' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','field.email') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'email' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','field.email') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"type":"email","placeholder":_vm.$t('field.email.placeholder')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_c('a-button',{style:({ 'margin-right': '4px' }),attrs:{"size":"large","icon":"left","disabled":_vm.formLoading},on:{"click":_vm.goBack}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('common.back'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('a-button',{staticClass:"btn-submit",attrs:{"type":"info","html-type":"submit","size":"large","loading":_vm.formLoading}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.$t('common.save'))+"\n\t\t\t\t\t")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }