<i18n locale="th" lang="yaml" >
page.title : "เพิ่มผู้ใช้งานสำหรับ {name}"
page.description : "เพิ่มผู้ใช้งานใหม่ในระบบสำหรับบริษัท {name}"
field.username : "ชื่อผู้ใช้งาน"
field.username.placeholder : "ระบุชื่อผู้ใช้งาน"
field.password : "รหัสผ่าน"
field.password.placeholder : "รหัสผ่าน"
field.user_role : "ตำแหน่งผู้ใช้งาน"
field.full_name : "ชื่อ-นามสกุล"
field.full_name.placeholder : "ชื่อ-นามสกุลของผู้ใช้งาน"
field.email : "Email"
field.email.placeholder: "ระบุ Email ที่ใช้ในการติดต่อ และ ส่งข้อมูล"
field.phone : "เบอร์โทรศัพท์"
field.phone.placeholder: "ระบุเบอร์โทรศัพท์มือถือ เพื่อใช้ในการติดต่อ"
field.image.help : "รูปผู้ใช้งาน ควรมีขนาด อย่างน้อย 256x256 px"
create_user.success : "ทำการสร้างผู้ใช้งาน {name} เรียบร้อย"
</i18n>

<template>
<div class="page">
	<my-page-header
		:title="$t('page.title',{ name: company.name })"
		:description="$t('page.description',{ name: company.name })"/>
	<a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
		<a-form
				layout="horizontal"
				:label-col="labelCol"
				:wrapper-col="wrapperCol"
				:form="formObj"
				@submit.prevent="handleSubmit">
				<input name="fakeusernameremembered" class="hidden"/>
				<input type="password" name="fakepasswordremembered" class="hidden"/>

				<a-form-item :help="$t('field.image.help')"
					style="text-align:center;margin-bottom:16px;"
					:label-col="{ span: 0 }"
					:wrapper-col="{ span: 24 }">
						<ImageUploader
							border-type="circle"
							size="large"
							:max-width="512"
							:max-height="512"
							@error="handleFileUploadError"
							@change="hadleFileUploadChange">
							<a-icon type="user" />
						</ImageUploader>
				</a-form-item>

				<a-form-item
					:label="$t('field.user_role')">
					<a-radio-group v-decorator="[
							'user_role' , { initialValue: 'user' } ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.user_role') },
							] },
						]">
						<a-radio value='admin'>{{$tenum('user_role','1')}}</a-radio>
						<a-radio value='user'>{{$tenum('user_role','2')}}</a-radio>
						<a-radio value='technician'>{{$tenum('user_role','3')}}</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item :label="$t('field.username')">
					<a-input
						v-decorator="[
							'username' ,
							{ rules: [
								{ required: true, message: $tt('validate.required','field.username') },
								{ min: 3 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
								{ max: 100 ,message: $tt('validate.length','field.username',{ min: 3,max: 100 }) } ,
							] },
						]"
						type="text"
						autocomplete="off"
						:placeholder="$t('field.username.placeholder')">
						<a-icon
							slot="prefix"
							type="user"/>
					</a-input>
				</a-form-item>
				<a-form-item :label="$t('field.password')" :extra="$t('common.password.pattern')">
					<a-input-password
						v-decorator="[
							'password' ,
							{ rules: [
								{ required: true, message: $tt('validate.required','field.password') },
								{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('common.password.pattern') },
							] },
						]"
						autocomplete="new-password"
						type="password"
						:placeholder="$t('field.password.placeholder')">
						<ion-icon slot="prefix" name="key-outline" class="anticon" />
					</a-input-password>
				</a-form-item>



				<a-form-item :label="$t('field.full_name')">
					<a-input
						v-decorator="[
							'full_name' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.full_name') },
							] },
						]"
						type="text"
						:placeholder="$t('field.full_name.placeholder')">
						<a-icon slot="prefix" type="user" />
					</a-input>
				</a-form-item>
				<a-form-item :label="$t('field.phone')">
					<a-input
						v-decorator="['phone']"
						type="tel"
						:max-length="20"
						:placeholder="$t('field.phone.placeholder')">
						<a-icon slot="prefix" type="phone" />
					</a-input>
				</a-form-item>
				<a-form-item :label="$t('field.email')">
					<a-input
						v-decorator="[
							'email' ,
							{ rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','field.email') },
							] },
						]"
						type="email"
						:placeholder="$t('field.email.placeholder')">
						<a-icon slot="prefix" type="mail" />
					</a-input>
				</a-form-item>


				<a-form-item v-bind="wrapperOnlyLayout">

					<a-button size="large" icon="left" :style="{ 'margin-right': '4px' }" :disabled="formLoading" @click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button type="info" html-type="submit" class="btn-submit" size="large" :loading="formLoading">
						<a-icon type="save" /> {{$t('common.save')}}
					</a-button>
				</a-form-item>
		</a-form>
	</a-card>
</div>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import ImageUploader from '@components/input/ImageUploader.vue'
import PageMixin from   '@mixins/PageMixin.vue'
import axios from "axios"
import {Radio} from "ant-design-vue"
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ImageUploader,
		"a-radio" : Radio,
		"a-radio-group" : Radio.Group,
	} ,
	mixins: [PageMixin,HasAntdFormMixin],
	props : {
		company : {
			type : Object,
			default : () => []
		}
	} ,
	data() {
		return {
			user : {} ,
			imageUploadData : {} ,
		}
	} ,
	methods : {
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		goBack() {
			this.$open({name:'company/users',params:{id:this.company.id}})
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					this.formLoading = true
					const formData = {
						...values ,
						'company_id' : this.company.id ,
						'image_file_base64' : this.imageUploadData
					};

					axios.post("/api/users/create",formData).then((response) => {
						const user = response.data.data.user;
						this.$router.push({
							name : 'company/users',
							params : {id : this.company.id}
						});
						this.$message.success(this.$t('create_user.success',{"name" : user.username}))
					}).catch((error) => {
						this.formSubmitErrors(error)
					}).finally(()=>{
						this.formLoading = false;
					});
				} else {
					this.displayValidateErrorMessage()
				}
			});
		}
	}
}
</script>
